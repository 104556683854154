export default {
  "language_chooser": {
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])}
  },
  "mp": {
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagesansicht"])},
    "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wochenansicht"])},
    "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatsansicht"])},
    "fit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komplettansicht"])},
    "noCampaignData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sind keine Daten verfügbar!"])},
    "notInInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Produkt liegt nicht im bestellbaren Zeitraum!"])},
    "noSmartphoneSupportYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Marketingplanung wird momentan nicht auf dem Smartphone angeboten. Wenn Sie dieses Feature nutzen wollen, müssen Sie die Applikation auf dem Tablet oder dem Desktop nutzen!"])},
    "global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global"])},
    "shopClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönlich"])},
    "confirm_campaign_delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie \"", _interpolate(_named("name")), "\" mit allen untergeordneten Elementen wirklich löschen?"])},
    "confirm_product_delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie das Produkt \"", _interpolate(_named("name")), "\" wirklich löschen?"])},
    "campaignEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Element-Editor"])},
    "campaignImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild"])},
    "productEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt-Editor"])},
    "maxDepthReached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale Tiefe erreicht"])},
    "displayChildrenInOneRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte einzeilig darstellen"])},
    "scheduleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Zeitraums"])}
  },
  "label": {
    "moreShopClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weitere"])},
    "actionRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens ein Produkt im Bundle erfordert eine Aktion!"])},
    "countBundleProducts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Keine Produkte im Bundle"]), _normalize(["Ein Produkt im Bundle"]), _normalize([_interpolate(_named("count")), " Produkte im Bundle"])])},
    "basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warenkorb"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheit"])},
    "bundleContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundlebestandteile"])},
    "bundle": {
      "bundleInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist ein Bundle das aus mehreren Produkten oder weiteren Bundles bestehen kann."])}
    },
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau"])},
    "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien"])},
    "orderItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellposition"])},
    "dateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
    "dateTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
    "absenceEditorCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit erstellen"])},
    "absenceEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit bearbeiten"])},
    "selectSubstitutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertreter auswählen"])},
    "workflow": {
      "instanceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instanz-ID"])},
      "taskId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task-ID"])},
      "withWorkflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Freigabe"])},
      "infoInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigabe läuft"])},
      "infoApprove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigabe erteilt"])},
      "infoReject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigabe NICHT erteilt"])},
      "infoApprovalRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Freigabe ist erforderlich!"])},
      "infoRevision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Überarbeitung ist erforderlich!"])},
      "workflowTasksActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offene Aufgaben"])},
      "workflowTasksCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossene Aufgaben"])},
      "workflowInstancesActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offene Anträge"])},
      "workflowInstancesCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossene Anträge"])},
      "state": {
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])},
        "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossen"])}
      },
      "tasksAssignedToCurrentUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mir zugewiesen"])},
      "tasksNotAssigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Zuweisung"])},
      "instanceDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antragdetails"])},
      "taskCandidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verantwortlicher"])},
      "taskCandidates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verantwortliche"])},
      "timeline": {
        "instance": {
          "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antrag erstellt"])},
          "ended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antrag beendet"])}
        },
        "task": {
          "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe erstellt"])},
          "ended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe beendet"])},
          "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe kommentiert"])},
          "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe beansprucht"])},
          "unclaim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anspruch aufgehoben"])},
          "candidate_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabenverantwortlichen hinzugefügt"])},
          "candidate_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabenverantwortlichen entfernt"])}
        }
      },
      "claimedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beansprucht von"])},
      "unclaimedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anspruch aufgehoben von"])}
    },
    "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlauf"])},
    "logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs"])},
    "withCustomization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Individualisierung"])},
    "withDefaultProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardprodukte"])},
    "withBundles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundles"])},
    "bundles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundles"])},
    "decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entscheidung"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentare"])},
    "newComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Kommentar"])},
    "commentedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentiert von"])},
    "taskList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow-Aufgaben"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiter"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "listEntries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listeneinträge"])},
    "limits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeige"])},
    "orderNewBrick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Baustein anfordern"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betreff"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht"])},
    "sendBrickOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anforderung senden"])},
    "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template"])},
    "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwendung"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
    "select_make_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
    "passwordChange": {
      "diffPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwortänderung"])},
      "passwordRepeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwortbestätigung"])},
      "char": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groß- und Kleinbuchstaben"])},
      "num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlen"])},
      "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonderzeichen"])},
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länge von mind. 12 Zeichen"])},
      "noWhite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Leerzeichen"])}
    },
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "termsAndConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AGB"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe"])},
    "userLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
    "favorizeProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt favorisieren"])},
    "defavorizeProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt defavorisieren"])},
    "doSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])},
    "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Account"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwender"])},
    "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandant"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandanten"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr"])},
    "less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weniger"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen"])},
    "addToBasket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In den Warenkorb"])},
    "dataCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenüberprüfung"])},
    "dataCheck_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Daten"])},
    "dataCheck_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmen-Daten"])},
    "gender_male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herr"])},
    "gender_female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frau"])},
    "gender_diverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divers"])},
    "gender_no_gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Angabe"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "clientAttributes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Felder"])},
    "product_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "product_detailDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktdetails"])},
    "product_productInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen"])},
    "product_artNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelnummer"])},
    "product_vendorArtNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelnummer(Lieferant)"])},
    "product_externalReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe Referenz"])},
    "product_customId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update-ID"])},
    "product_processingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten"])},
    "product_quantityStrategy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staffelpreise"])},
    "product_deactivationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fällig am"])},
    "product_deliveryOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versand"])},
    "deliveryAddressComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferanweisung"])},
    "vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferanten"])},
    "viewMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansicht"])},
    "setNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt festlegen"])},
    "selectDeliveryOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie pro Bestellposition die Versandoption"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "minDeliveryTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestlieferzeit"])},
    "maxDeliveryTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale Lieferzeit"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])},
    "products": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Produkte"]), _normalize(["Produkt"]), _normalize(["Produkte"])])},
    "worldRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welt"])},
    "deliveryTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferzeit"])},
    "deliveryCosts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["zzgl. ", _interpolate(_named("value")), " Versand"])},
    "lessBudgetCosts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["abz. ", _interpolate(_named("value")), " ", _interpolate(_named("txt"))])},
    "lessBudget": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["abz. ", _interpolate(_named("txt"))])},
    "includeAdditionalCosts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["inkl. ", _interpolate(_named("txt"))])},
    "plusAdditionalCosts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["zzgl. ", _interpolate(_named("txt"))])},
    "additionalCosts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["zzgl. ", _interpolate(_named("value")), " ", _interpolate(_named("txt"))])},
    "additionalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusatzkosten"])},
    "deliveredAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandt am"])},
    "incl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inkl."])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
    "moreProductInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Informationen"])},
    "excl_taxValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["zzgl. ", _interpolate(_named("value")), "% MwSt."])},
    "incl_taxValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["inkl. ", _interpolate(_named("value")), "% MwSt."])},
    "plus_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zzgl. Versandkosten"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
    "priceNet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettopreis"])},
    "priceGross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruttopreis"])},
    "priceUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis pro Einheit"])},
    "priceXUnit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Preis pro ", _interpolate(_named("x")), " Einheiten"])},
    "ownCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigene Kostenstelle"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuern"])},
    "deliveryCostsNet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandkosten"])},
    "budget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budget"])},
    "budgets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budgets"])},
    "budgets_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Budgets"])},
    "budget_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorien"])},
    "budget_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte"])},
    "budgets_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Budgets"])},
    "budgets_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktive Budgets"])},
    "budget_type_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budget"])},
    "budget_type_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtuelles Budget"])},
    "budget_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlauf"])},
    "budget_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhöhung beantragen"])},
    "budget_request_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Benötigtes Budget in ", _interpolate(_named("currency"))])},
    "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig ab"])},
    "validTill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig bis"])},
    "remainingAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restbetrag"])},
    "reducingValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgezogener Betrag"])},
    "noUploadedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei hochladen"])},
    "customFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiteres"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellungen"])},
    "order_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellt am"])},
    "order_ByUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwender"])},
    "order_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestell-Nr."])},
    "order_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelldetails"])},
    "order_last_order": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zuletzt bestellt am ", _interpolate(_named("date")), " Uhr von ", _interpolate(_named("name"))])},
    "order_last_order_noName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zuletzt bestellt am ", _interpolate(_named("date")), " Uhr"])},
    "order_no_orders_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Bestellungen gefunden"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewicht"])},
    "unit_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stk."])},
    "inifinty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbegrenzt"])},
    "minOrderQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von"])},
    "maxOrderQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis"])},
    "stepOrderQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staffelung"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ab"])},
    "available_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbare Dateien"])},
    "usedAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbraucht"])},
    "editAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse bearbeiten"])},
    "editAddresDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardadresse bearbeiten"])},
    "createAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse anlegen"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adressen"])},
    "addressType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresstyp"])},
    "allAddresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Adressen"])},
    "mainAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptadresse"])},
    "mainAddresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptadressen"])},
    "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferadresse"])},
    "deliveryAddresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferadressen"])},
    "invoiceAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsadresse"])},
    "invoiceAddresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsadressen"])},
    "otherAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Adresse"])},
    "likeMainAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie Hauptadresse"])},
    "likeDeliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie Lieferadresse"])},
    "paymentState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung"])},
    "paymentState_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlt"])},
    "paymentState_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstehend"])},
    "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsart"])},
    "paymentOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Rechnung"])},
    "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe"])},
    "similarProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ähnliche Produkte"])},
    "showProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeige Produkte"])},
    "updateBasketItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "setAddressComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferanweisungen"])},
    "orderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Ihren Auftrag!"])},
    "orderNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellnr."])},
    "orderedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellt am"])},
    "orderedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellt von"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "list_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
    "list_7days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzten 7 Tage"])},
    "list_1month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzten Monat"])},
    "list_4month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzten 4 Monate"])},
    "list_6month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letztes halbe Jahr"])},
    "list_1year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letztes Jahr"])},
    "list_2year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzten 2 Jahre"])},
    "list_3year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzten 3 Jahre"])},
    "orderState_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])},
    "orderState_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versendet"])},
    "orderState_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storniert"])},
    "orderState_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilversendet"])},
    "expirationTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigkeitsdauer"])},
    "expirationTimeOptions": {
      "300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 Minuten"])},
      "3600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Stunde"])},
      "43200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 Stunden"])},
      "86400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 Stunden"])},
      "172800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["48 Stunden"])},
      "259200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["72 Stunden"])},
      "604800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Woche"])},
      "2419200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 Wochen"])}
    },
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
    "multiAddressTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferung an"])},
    "colorType": {
      "hex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HEX"])},
      "cmyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CMYK"])},
      "ral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL"])},
      "pantone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pantone"])}
    },
    "news": {
      "type_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Nachrichten"])},
      "type_-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungelesene Nachrichten"])},
      "type_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
      "type_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe"])},
      "type_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systemmeldung"])},
      "type_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prospekt"])},
      "type_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage"])},
      "type_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwort"])},
      "type_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigabeanforderung"])},
      "type_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budgetanfrage"])},
      "type_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bausteinanforderung"])},
      "type_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frage zu einem Produkt"])},
      "type_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forum"])},
      "type_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privatchat"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht"])},
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichten"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betreff"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Nachricht"])},
      "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfänger"])},
      "admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administratoren"])},
      "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medien"])},
      "markRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelesen markieren"])},
      "createProductQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragen zum Produkt"])}
    },
    "assetLibrary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assetsammlungen"])},
    "assetEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assets"])},
    "productCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktkategorien"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte"])},
    "entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einträge"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
    "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antworten"])},
    "replyContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwort"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herunterladen"])},
    "assetType": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild / Grafik"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbe"])},
      "htmlFragment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML-Fragment"])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei"])},
      "font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schrift"])}
    },
    "imageDimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maße"])},
    "colorDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbtiefe"])},
    "colorModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbraum"])},
    "assetGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
    "currentClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiver Mandant"])},
    "moreClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Mandanten"])},
    "switchClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandanten wechseln"])},
    "campaignRunId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individualisierung ID"])},
    "campaignRunDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individualisierung Name"])},
    "contentGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhaltsgruppen"])},
    "toAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
    "endedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beendet am"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt am"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt von"])},
    "requestedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beantragt von"])},
    "requestedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beantragt am"])},
    "loading_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten werden geladen"])},
    "userSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
    "substitutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertreter"])},
    "absence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit"])},
    "unreadNotificationMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tägliche E-Mail über neue Mitteilungen erhalten"])},
    "instantNotificationMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofortige E-Mail bei neuen Nachrichten"])},
    "workflowNotificationMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow bezogene E-Mails"])},
    "downloadProductFilesEmailNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt Download-Dateien"])},
    "tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtern"])},
    "filterHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "changeLocale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache wechseln"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historie"])},
    "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitplan"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum"])},
    "backgroundColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hintergrundfarbe"])},
    "fontColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schriftfarbe"])},
    "bgColorActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitstrahl (Zukunft)"])},
    "bgColorInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitstrahl (Vergangenheit)"])},
    "editCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Element bearbeiten"])},
    "addCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untergeordnetes Element erstellen"])},
    "addMainCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Haupteintrag erstellen"])},
    "editProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt bearbeiten"])},
    "addProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt hinzufügen"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassung"])},
    "freeShippingValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kostenloser Versand ab ", _interpolate(_named("value"))])},
    "combineWithAnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muss alle enthalten"])},
    "isInWatchlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereits in der Merkliste"])},
    "isInBasket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereits im Warenkorb"])},
    "hasVariants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besitzt Varianten"])},
    "addressBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adressbuch"])},
    "fullView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständige Ansicht"])},
    "items": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Einträge"]), _normalize(["Eintrag"]), _normalize(["Einträge"])])}
  },
  "contact": {
    "label": {
      "user_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwender"])},
      "user_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwenderanschrift"])},
      "user_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwenderkontaktdaten"])},
      "client_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
      "client_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "client_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt-Details"])},
      "client_attributes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Daten"])},
      "user_attributes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Daten"])}
    },
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matchcode"])},
    "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatar"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschlecht"])},
    "orgId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe Kundenreferenz"])},
    "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webseite"])},
    "countryId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "phoneBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon (geschäftlich)"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiltelefon"])},
    "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
    "faxBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax (geschäftlich)"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "nameSupplement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namenszusatz"])},
    "academicTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akademischer Titel"])},
    "titleOfNobility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adelstitel"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strasse"])},
    "streetExt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strasse-Erweiterung"])},
    "premise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelände"])},
    "locality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
    "houseNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausnr."])},
    "houseNoExt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausnr.-Erweiterung"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundesland"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
    "companySalutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma-Anrede"])},
    "companySupplement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma-Erweiterung"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "contactType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakttyp"])},
    "lastnamePrefixFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Präfix des ersten Nachnamens"])},
    "lastnameFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erster Nachname"])},
    "lastnameIndicator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trennzeichen der Nachnamen"])},
    "lastnameSecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweiter Nachname"])},
    "lastnamePrefixSecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Präfix des zweiten Nachnamens"])},
    "qualificationIntermediateSecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel zum Nachnamen"])},
    "qualificationSubsequent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Titel"])},
    "postbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postfach"])},
    "postboxZipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postfach PLZ"])},
    "postboxCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postfach Ort"])},
    "stateExt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundesland-Erw."])},
    "privatePerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privatperson"])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unbekannt"])},
    "premiseExt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geländeteilnummer"])},
    "suburb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortsteil"])}
  },
  "message": {
    "helloUser": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hallo ", _interpolate(_named("name"))])},
    "login-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte melden Sie sich an und achten Sie auf die Groß- und Kleinschreibung."])},
    "passwordRecoverSucceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie erhalten in Kürze ein neues Passwort per E-Mail"])},
    "passwordRecoverError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte prüfen Sie Ihren Benutzernamen"])},
    "passwordChangeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte prüfen Sie Ihre Eingabe"])},
    "passwordChangeSucceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Passwort wurde erfolgreich geändert."])},
    "passwordForgotten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie den Benutzernamen an, mit welchem Sie registriert sind. Wir senden Ihnen umgehend ein neues Passwort zu."])},
    "passwordChangeCriteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muss folgende Kriterien erfüllen:"])},
    "noFavorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben bisher keine Produkte favorisiert."])},
    "noWatchlistItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine Einträge."])},
    "noProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es befinden sich keine Produkte in dieser Kategorie."])},
    "addToCartSucceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das Produkt wurde Ihrem <a href='", _interpolate(_named("url")), "'>Warenkorb</a> hinzugefügt."])},
    "productAddedToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Produkt wurde Ihrem Warenkorb hinzugefügt"])},
    "moreProductsInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Produkte im Warenkorb"])},
    "addToWatchlistSucceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das Produkte wurde Ihrer <a href='", _interpolate(_named("url")), "'>Merkliste</a> hinzugefügt."])},
    "upload_wrong_filetype": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bitte laden Sie Ihre Dateien in folgendem Datei-Format hoch: ", _interpolate(_named("fileExt"))])},
    "filetype_not_supported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Datei-Format wird nicht unterstützt."])},
    "budgetsUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben Produkte im Rahmen eines Budgets hinterlegt. Bei Kauf werden die jeweiligen Budgets belastet."])},
    "basketItem_not_orderable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Bestellposition ist nicht bestellbar. Prüfen Sie die Bestellmenge und sofern vorhanden Budget und Individualisierung."])},
    "basketItem_not_orderable_please_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Bestellposition ist nicht bestellbar. Bitte entfernen Sie das Produkt aus dem Warenkorb."])},
    "basketItem_not_orderable_reason_stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Bestellposition ist nicht bestellbar. Prüfen Sie die Bestellmenge."])},
    "basketItem_not_orderable_campaignRun_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Bestellposition ist nicht bestellbar, da die Individualisierung gesperrt ist."])},
    "basketItem_not_orderable_reason_cr_not_ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Bestellposition ist nicht bestellbar, da die Individualisierung nicht abgeschlossen ist."])},
    "basketItem_not_orderable_reason_product_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Bestellposition ist nicht bestellbar, da Angaben fehlen."])},
    "basketItem_not_orderable_reason_quantity_not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Bestellposition ist nicht bestellbar, da die Bestellmenge ungültig ist."])},
    "campaignRun_not_ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individualisierung nicht abgeschlossen"])},
    "budgetToLow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Budget deckt nicht die Kosten ab."])},
    "budgetCharge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bei Auswahl wird das Budget um ", _interpolate(_named("value")), " belastet."])},
    "budgetAvailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("budgetName")), ": ", _interpolate(_named("value")), " verfügbar."])},
    "basketCalculationRunning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Warenkorb wird berechnet."])},
    "basketIsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Warenkorb ist leer."])},
    "deleteBasketItem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie die Position \"", _interpolate(_named("name")), " (", _interpolate(_named("quantity")), ")\" wirklich löschen?"])},
    "deleteBundle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie das Bundle \"", _interpolate(_named("name")), "\" wirklich löschen?"])},
    "sameAsDeliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identisch mit Lieferadresse"])},
    "thanksForOrdering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Ihren Auftrag!"])},
    "errorsDuringOrdering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider ist ein Fehler während des Bestellvorgangs aufgetreten. Bitte prüfen Sie den Warenkorb erneut."])},
    "orderItemPrice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quantity")), " ", _interpolate(_named("unit")), " zu ", _interpolate(_named("price"))])},
    "orderItemDeliveryCosts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["zzgl. ", _interpolate(_named("deliveryCosts")), " Versand"])},
    "noDeliveryTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofort verfügbar nach Bezahlung (Download)"])},
    "noDeliveryCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Versandkosten"])},
    "multiAddressDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bestellung wird an verschiedene Adressen geliefert. Nähere Einzelheiten sind an den Produkten ausgeschildert."])},
    "sendSecureDownloadLink": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sehr geehrte Damen und Herren, sehr geehrte Interessierte,%0D%0Ddas Dokument ist unter folgendem Link zugänglich: ", _interpolate(_named("link"))])},
    "multiAddressQuantityError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Summe der Einzellieferungen muss der Gesamtbestellmenge entsprechen."])},
    "multiAddressAddressesNotUnique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jede Lieferadresse darf nur einmal pro Bestellposition vorkommen."])},
    "multiAddressIdMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferadresse fehlt."])},
    "basketItem_not_deliverable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferung ist nicht möglich."])},
    "confirmDeleteAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Adresse wirklich löschen?"])},
    "confirm_delete_watchlistItem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie das Produkt \"", _interpolate(_named("name")), "\" wirklich entfernen?"])},
    "search_is_running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche läuft ..."])},
    "noSearchAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Kategorie zum Suchen vorhanden"])},
    "noSearchHits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Treffer"])},
    "budgets_no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Budgets gefunden"])},
    "budget_request_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Antrag wurde erfolgreich übertragen."])},
    "budgetExceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Belastung mindestens eines Budgets übersteigt in Summe den verfügbaren Betrag."])},
    "result_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Daten gefunden."])},
    "createProductQuestionSucceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Frage(n) wurden erfolgreich übertragen."])},
    "createProductQuestionFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Frage(n) konnten leider nicht übermittelt werden."])},
    "generatingYourDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumente werden erstellt. In Abhängigkeit des Dokuments kann dies eine Weile dauern."])},
    "checkoutGroupDeliveryChooseError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte prüfen Sie die Auswahl der Versandoption"])},
    "deliveryOptionNoLongerValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die gewählte Versandoption ist nicht länger gültig."])},
    "noDeliveryOptionSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Versandoption gewählt."])},
    "noDeliveryOptionAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An die ausgewählte Lieferadresse ist kein Versand möglich."])},
    "styleguide_entry_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Eintrag konnte nicht gefunden werden."])},
    "invalidAddressesError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine oder mehrere Adressen sind fehlerhaft."])},
    "invalidAddressError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse enthält Fehler."])},
    "error_address_not_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Adresse konnte nicht gelöscht werden."])},
    "campaignrunWasLocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Individualisierung wurde durch den Administrator gesperrt."])},
    "lastUpdate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zuletzt bearbeitet am ", _interpolate(_named("at")), " Uhr von ", _interpolate(_named("by"))])},
    "noDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Dokumente gefunden."])},
    "documentsAreLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumente werden geladen."])},
    "urlWithProtocolError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert muss eine gültige URL darstellen, inkl. Protkol (z.B. https://)"])},
    "noComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sind keine Kommentare vorhanden."])},
    "informAboutQuantityAdaption": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihre gewünschte Bestellmenge von ", _interpolate(_named("wantedQuantity")), " ", _interpolate(_named("unit")), " wurde auf ", _interpolate(_named("isQuantity")), " ", _interpolate(_named("unit")), " angepasst, da die gewählte Bestellmenge nicht erlaubt ist."])},
    "decisionIsFinal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Entscheidung kann nicht rückgängig gemacht werden."])},
    "maintenanceIsActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Software-Lösungen werden gerade für Sie aktualisiert!\n\nIn Kürze stehen für Sie neue Funktionen bereit.\nVielen Dank für Ihr Verständnis."])},
    "instanceOrderItemDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die zugehörige Bestellposition wurde bereits entfernt."])},
    "basketItem_not_orderable_reason_cr_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Individualisierung wurde gelöscht."])}
  },
  "notification": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider ist ein Fehler aufgetreten"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion erfolgreich"])}
  },
  "btn": {
    "workflow": {
      "startApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigabe anfordern"])},
      "approvalInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigabe läuft ..."])},
      "instanceDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antragdetails anzeigen"])}
    },
    "view-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste"])},
    "view-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kacheln"])},
    "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigeben"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
    "rejectWithRevision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurückspielen"])},
    "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beanspruchen"])},
    "unclaim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anspruch beenden"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
    "login_classic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername und Passwort"])},
    "recoverPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
    "toLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Anmeldung"])},
    "requestPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort anfordern"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern"])},
    "loadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "save_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern & weiter"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Kasse gehen"])},
    "quickCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt kaufen"])},
    "pushToWatchlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In die Merkliste"])},
    "pushBundleToWatchlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundle in die Merkliste"])},
    "removeFromBasket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "editBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundle bearbeiten"])},
    "removeBundleFromBasket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundle löschen"])},
    "editCustomization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individualisieren"])},
    "disablePrintView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druckansicht beenden"])},
    "openPrintDialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt drucken"])},
    "openPrintView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druckansicht öffnen"])},
    "continueShopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter einkaufen"])},
    "openOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu den Bestellungen"])},
    "openOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Bestellung"])},
    "orderDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelldetails"])},
    "reorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut bestellen"])},
    "orderNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellen"])},
    "trackDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferung verfolgen"])},
    "sendToAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An diese Adresse versenden"])},
    "newAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Adresse hinzufügen"])},
    "shareLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link teilen"])},
    "createLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link erzeugen"])},
    "sendAsEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als E-Mail versenden"])},
    "startProjectRun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individualisierung starten"])},
    "continueProjectRun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individualisierung fortsetzen"])},
    "startMultiAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An mehrere Adressen versenden"])},
    "addMultiAddressEntry1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An zusätzliche Adresse liefern?"])},
    "addMultiAddressEntry2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dabei kann es zu höheren Versandkosten kommen."])},
    "removeDeliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse entfernen"])},
    "deactivateMultiAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles an eine Adresse liefern"])},
    "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesen"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht senden"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigen"])},
    "showAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle anzeigen"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionen"])},
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beantragen"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])}
  },
  "startMsg": {
    "Ndx\\Exception\\EeUserGatewayAuthProcessDenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungsmethode unzulässig"])},
    "Ndx\\Exception\\EeUserGatewayUnknownUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte prüfen Sie Ihren Benutzernamen und Ihr Passwort"])},
    "Ndx\\Exception\\EeUserGatewayWrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte prüfen Sie Ihren Benutzernamen und Ihr Passwort"])},
    "Ndx\\Exception\\EeUserGatewayClientMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Benutzerkonto ist inaktiv. Bitte wenden Sie sich an den Support."])},
    "Ndx\\Exception\\EeUserGatewayDisabledUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Benutzerkonto wurde gesperrt. Bitte wenden Sie sich an den Support, um Ihr Konto wieder freischalten zu lassen!"])},
    "Ndx\\Exception\\EeUserGatewayLockedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde zu oft ein falsches Passwort eingegeben. Aus Sicherheitsgründen wurde Ihr Benutzerkonto gesperrt. Bitte wenden Sie sich an den Support, um Ihr Konto wieder freischalten zu lassen!"])},
    "Ndx\\Exception\\EeUserGatewayInactiveOrganization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Account wurde durch einen Servicemitarbeiter gesperrt. Zur Lösung des Problems wenden Sie sich bitte an den Support!"])},
    "Ndx\\Exception\\EeUserGatewayInactivePartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Account wurde durch einen Servicemitarbeiter gesperrt. Zur Lösung des Problems wenden Sie sich bitte an den Support!"])},
    "Ndx\\Exception\\EeApplicationInMaintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systemwartung"])},
    "Ndx\\Exception\\EeUserGatewayInsufficientPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Zugang ist nicht freigeschaltet"])},
    "Ndx\\Exception\\EeApplicationMaintenanceAnnouncement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systemwartung bevorstehend"])},
    "KnpU\\OAuth2ClientBundle\\Security\\Exception\\IdentityProviderAuthenticationException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IdP-Konfigurationsfehler"])},
    "KnpU\\OAuth2ClientBundle\\Security\\Exception\\NoAuthCodeAuthenticationException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IdP-Konfigurationsfehler"])},
    "Ndx\\AuthBundle\\Security\\Exception\\NdxMissingAuthorizationCodeAuthenticationException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IdP-Konfigurationsfehler"])},
    "Ndx\\AuthBundle\\Security\\Exception\\NdxInvalidStateAuthenticationException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IdP-Konfigurationsfehler"])},
    "Ndx\\AuthBundle\\Security\\Exception\\NdxIdentityProviderAuthenticationException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IdP-Konfigurationsfehler"])}
  },
  "units": {
    "eur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€"])},
    "usd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$"])},
    "gbp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["£"])},
    "chf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFr."])},
    "yen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¥"])},
    "workingday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Werktage"]), _normalize(["Werktag"]), _normalize(["Werktage"])])},
    "short": {
      "pieces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stk."])}
    }
  },
  "switch": {
    "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ja"])},
    "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nein"])}
  },
  "tooltip": {
    "closeCluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe schließen"])},
    "openCluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe öffnen"])}
  },
  "removeConfig": {
    "approveRemoveText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um diese Individualisierung zu bearbeiten, müssen Sie zuerst die bereits erteilte Freigabe entziehen."])},
    "approveRemoveBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigabe entziehen"])},
    "approveRemoveHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigabe entziehen"])},
    "approveRemoveBtnDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])}
  },
  "pageNotFound": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 - Seite nicht gefunden"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Seite, die Sie suchen, wurde möglicherweise entfernt,\nihr Name wurde geändert oder sie ist vorübergehend nicht verfügbar."])}
  }
}